<template>
  <v-card>
    <v-container>
      <v-row>
        <h2>Kunde</h2>
      </v-row>

      <v-row>
        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.profile.uid"
            :key="project.customerUID + '/firstName'"
            :collection="'profiles'"
            :doc="project.customer.profile"
            :field="'firstName'"
            :label="'Fornavn'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.profile.uid"
            :key="project.customerUID + '/lastName'"
            :collection="'profiles'"
            :doc="project.customer.profile"
            :field="'lastName'"
            :label="'Etternavn'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/phone'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'phone'"
            :label="'Telefonnummer'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/email'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'email'"
            :label="'Epost'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.user.uid"
            :key="project.customerUID + '/postalCode'"
            :collection="'users'"
            :doc="project.customer.user"
            :field="'postalCode'"
            :label="'Postnummer'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/city'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'city'"
            :label="'By'"
          />
        </v-col>

        <v-col cols="6">
          <document-field-picker
            :id="project.ID"
            :key="project.customerUID + '/buyingFor'"
            :collection="'projects'"
            :doc="project"
            :field="'buyingFor'"
            :label="'Hvem er undervisningen for?'"
            :alternatives="[
            {
              value: '',
              text: '(Ikke valgt)'
            },
            {
              value: 'child',
              text: 'child'
            },
            {
              value: 'self',
              text: 'self'
            }
          ]"
          />
        </v-col>

        <v-col cols="6">
          <document-field-picker
            :id="project.customer.account.uid"
            :key="project.customerUID + '/secondaryPerson'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'secondaryPerson'"
            :label="'Hvem er sekundær kontaktperson?'"
            :alternatives="[
            {
              value: '',
              text: '(Ikke valgt)'
            },
            {
              value: 'mother',
              text: 'Mor'
            },
            {
              value: 'father',
              text: 'Far'
            },
            {
              value: 'student',
              text: 'Eleven'
            },
            {
              value: 'other',
              text: 'Andre'
            }
          ]"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/secondaryPersonName'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'secondaryPersonName'"
            :label="'Navn på sekundær kontaktperson?'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/secondaryPersonEmail'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'secondaryPersonEmail'"
            :label="'Hva er epost til sekundær kontaktperson?'"
          />
        </v-col>

        <v-col cols="6">
          <document-prop-text-field-editor
            :id="project.customer.account.uid"
            :key="project.customerUID + '/secondaryPersonPhone'"
            :collection="'accounts'"
            :doc="project.customer.account"
            :field="'secondaryPersonPhone'"
            :label="'Telefon til sekundær kontaktperson?'"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Lærer</h2>
        </v-col>

        <v-col cols="12">
          <v-row v-if="project.sellerUID">
            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.profile.uid"
                :key="project.sellerUID + '/firstName'"
                :collection="'profiles'"
                :doc="project.seller.profile"
                :field="'firstName'"
                :label="'Fornavn'"
              />
            </v-col>

            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.profile.uid"
                :key="project.sellerUID + '/lastName'"
                :collection="'profiles'"
                :doc="project.seller.profile"
                :field="'lastName'"
                :label="'Etternavn'"
              />
            </v-col>

            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.account.uid"
                :key="project.sellerUID + '/phone'"
                :collection="'accounts'"
                :doc="project.seller.account"
                :field="'phone'"
                :label="'Telefonnummer'"
              />
            </v-col>

            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.account.uid"
                :key="project.sellerUID + '/email'"
                :collection="'accounts'"
                :doc="project.seller.account"
                :field="'email'"
                :label="'Epost'"
                :disabled="true"
              />
            </v-col>

            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.user.uid"
                :key="project.sellerUID + '/postalCode'"
                :collection="'users'"
                :doc="project.seller.user"
                :field="'postalCode'"
                :label="'Postnummer'"
              />
            </v-col>

            <v-col cols="6">
              <document-prop-text-field-editor
                :id="project.seller.account.uid"
                :key="project.sellerUID + '/city'"
                :collection="'accounts'"
                :doc="project.seller.account"
                :field="'city'"
                :label="'By'"
              />
            </v-col>

            <v-col cols="12">
              <h4>Bytte PL</h4>
              <p>OBS! Bytte av PL fører IKKE over, eller kansellerer timer som er satt opp med tidligere PL.</p>
            </v-col>

            <v-col cols="8">
              <v-text-field v-model="newSellerUID" label="UID til ny PL" />
            </v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                :disabled="newSellerUID.length !== 28"
                @click="changeSellerForProject"
              >
                Bytt PL
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-else cols="12">
            <h4 style="color: red">
              Ingen lærer valgt ennå.
            </h4>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'CustomerDialog',
  props: ['project'],
  data() {
    return {
      newSellerUID: '',
    };
  },
  methods: {
    async changeSellerForProject() {
      await this.$axios.put('/admin/projects/changeSeller/' + this.project.ID + '/' + this.newSellerUID);
      this.$store.dispatch('displayAlert', {
        color: 'green',
        message: 'PL byttet',
      });
    },
  },
};
</script>
