var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-container",
        [
          _c("v-row", [_c("h2", [_vm._v("Kunde")])]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/firstName",
                    attrs: {
                      id: _vm.project.customer.profile.uid,
                      collection: "profiles",
                      doc: _vm.project.customer.profile,
                      field: "firstName",
                      label: "Fornavn"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/lastName",
                    attrs: {
                      id: _vm.project.customer.profile.uid,
                      collection: "profiles",
                      doc: _vm.project.customer.profile,
                      field: "lastName",
                      label: "Etternavn"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/phone",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "phone",
                      label: "Telefonnummer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/email",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "email",
                      label: "Epost"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/postalCode",
                    attrs: {
                      id: _vm.project.customer.user.uid,
                      collection: "users",
                      doc: _vm.project.customer.user,
                      field: "postalCode",
                      label: "Postnummer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/city",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "city",
                      label: "By"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-field-picker", {
                    key: _vm.project.customerUID + "/buyingFor",
                    attrs: {
                      id: _vm.project.ID,
                      collection: "projects",
                      doc: _vm.project,
                      field: "buyingFor",
                      label: "Hvem er undervisningen for?",
                      alternatives: [
                        {
                          value: "",
                          text: "(Ikke valgt)"
                        },
                        {
                          value: "child",
                          text: "child"
                        },
                        {
                          value: "self",
                          text: "self"
                        }
                      ]
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-field-picker", {
                    key: _vm.project.customerUID + "/secondaryPerson",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "secondaryPerson",
                      label: "Hvem er sekundær kontaktperson?",
                      alternatives: [
                        {
                          value: "",
                          text: "(Ikke valgt)"
                        },
                        {
                          value: "mother",
                          text: "Mor"
                        },
                        {
                          value: "father",
                          text: "Far"
                        },
                        {
                          value: "student",
                          text: "Eleven"
                        },
                        {
                          value: "other",
                          text: "Andre"
                        }
                      ]
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/secondaryPersonName",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "secondaryPersonName",
                      label: "Navn på sekundær kontaktperson?"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/secondaryPersonEmail",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "secondaryPersonEmail",
                      label: "Hva er epost til sekundær kontaktperson?"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("document-prop-text-field-editor", {
                    key: _vm.project.customerUID + "/secondaryPersonPhone",
                    attrs: {
                      id: _vm.project.customer.account.uid,
                      collection: "accounts",
                      doc: _vm.project.customer.account,
                      field: "secondaryPersonPhone",
                      label: "Telefon til sekundær kontaktperson?"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h2", [_vm._v("Lærer")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.project.sellerUID
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/firstName",
                                attrs: {
                                  id: _vm.project.seller.profile.uid,
                                  collection: "profiles",
                                  doc: _vm.project.seller.profile,
                                  field: "firstName",
                                  label: "Fornavn"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/lastName",
                                attrs: {
                                  id: _vm.project.seller.profile.uid,
                                  collection: "profiles",
                                  doc: _vm.project.seller.profile,
                                  field: "lastName",
                                  label: "Etternavn"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/phone",
                                attrs: {
                                  id: _vm.project.seller.account.uid,
                                  collection: "accounts",
                                  doc: _vm.project.seller.account,
                                  field: "phone",
                                  label: "Telefonnummer"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/email",
                                attrs: {
                                  id: _vm.project.seller.account.uid,
                                  collection: "accounts",
                                  doc: _vm.project.seller.account,
                                  field: "email",
                                  label: "Epost",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/postalCode",
                                attrs: {
                                  id: _vm.project.seller.user.uid,
                                  collection: "users",
                                  doc: _vm.project.seller.user,
                                  field: "postalCode",
                                  label: "Postnummer"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("document-prop-text-field-editor", {
                                key: _vm.project.sellerUID + "/city",
                                attrs: {
                                  id: _vm.project.seller.account.uid,
                                  collection: "accounts",
                                  doc: _vm.project.seller.account,
                                  field: "city",
                                  label: "By"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c("h4", [_vm._v("Bytte PL")]),
                            _c("p", [
                              _vm._v(
                                "OBS! Bytte av PL fører IKKE over, eller kansellerer timer som er satt opp med tidligere PL."
                              )
                            ])
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "UID til ny PL" },
                                model: {
                                  value: _vm.newSellerUID,
                                  callback: function($$v) {
                                    _vm.newSellerUID = $$v
                                  },
                                  expression: "newSellerUID"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.newSellerUID.length !== 28
                                  },
                                  on: { click: _vm.changeSellerForProject }
                                },
                                [
                                  _vm._v(
                                    "\n              Bytt PL\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("v-row", { attrs: { cols: "12" } }, [
                        _c("h4", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "\n            Ingen lærer valgt ennå.\n          "
                          )
                        ])
                      ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }